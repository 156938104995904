<template>
  <login></login>
</template>

<script>
import Login from "@c_modules/Login/Login"

export default ({
  name: "LoginView",
  components: {
    Login
  },
  data () {
    return {}
  },
  methods: {},
  created () {
  }
})
</script>

