import 'signalr'
import * as urls from "~/lib/urls";

const CONFIG = {
    WeChatLoginUrl: "Security/WeChatLogin",
    HubConnectionUrl: "https://wx.bom.ai/messageConnection",
    apiDomain: urls.getErpApiBaseUrl()
}

export default {
    name: 'signalrWeChatLogin',
    data() {
        return {
            connection: {}
        }
    },

    methods: {
        // 启动signalR链接
        async startConnection() {
            this.connection = $.connection(CONFIG.HubConnectionUrl);
            this.connection.start({ jsonp: true })
                .done((data) => {
                    this.GetReloadQRCode(data.id);
                })
                .fail((message) => {
                    this.showError('服务器连接失败');
                });

            //服务器回调
            this.connection.received((data) => {
                let searchChar = "openid=";
                if (data.indexOf(searchChar) > -1) {
                    //截取openid
                    let index = data.indexOf("openid=") + searchChar.length;
                    let openId = data.substring(index)
                    if (openId === null || openId.length === 0) {
                        this.showError('获取OpnId失败，请扫码重试');
                        return;
                    }
                    //使用openId登录
                    this.WeChatLogin(openId);
                }
            });
        },

        // 微信登录二维码
        async GetReloadQRCode(tokenId) {
            this.QRCodeUrl = `${CONFIG.apiDomain}/Security/GetWeChatLoginQRCode?tokenId=${tokenId}`;
        },

        //微信扫码登录
        async WeChatLogin(openId) {
            //调用微信登录方法
            let param = {
                OpenId: openId,
                LoginType: 20 // Web 登录
            };
            this.loading = true;
            let isSuccess = false;
            let loginResult = {};
            await this.$post(CONFIG.WeChatLoginUrl, param, (data, logic) => {
                if (data && logic.code === 200) {
                    loginResult = data;
                    this.showSuccess('登录成功, 正在跳转');
                    isSuccess = true;
                    //登录成功关闭signalR连接
                    this.connection.stop();
                }
                else {
                    let msg = logic && logic.msg || "登录失败，请重试"
                    this.showError(msg)
                }
                this.loading = false;
            }).finally(() => {
                this.loading = false;
            })

            if (isSuccess) {
                await this.loginSuccess(loginResult);
            }
        }

    },

    mounted() {
        //启动链接
        this.startConnection();
    }
}
